<template>
  <!--begin::Farm controller-->
  <div class="card card-custom gutter-b dashboard-farm-controller" v-show="showExample">
    <!--begin::controller-tabs-->
    <div class="controller-tabs">
        <div class="card-body pt-10">
          <div class="row opacity-30" @click="showModal = !showModal">
            <div class="col-12 col-lg-12 col-xxl-12">
              <h4 class="p-5 bg-secondary text-center">
                <span>{{example}}</span>
              </h4>
            </div>
            <div class="col-6 col-lg-2 col-xxl-1">
              <stats-widget1 :temperature="temperature"></stats-widget1>
            </div>
            <div class="col-6 col-lg-2 col-xxl-1">
              <stats-widget13 :temperature="temperature" :waterTemperature="waterTemperature"></stats-widget13>
            </div>

            <div class="col-12 col-lg-8 col-xxl-5">
              <group-widget
                  :humidity="humidity"
                  :co2="co2"
                  :ph="ph"
                  :ec="ec"
              ></group-widget>
            </div>
            <div class="col-12 col-lg-6 col-xxl-2">
              <stats-widget6
                  :led="led"
              ></stats-widget6>
              <stats-widget7
                  :fan="fan"
              ></stats-widget7>
            </div>

            <div class="col-12 col-lg-6 col-xxl-3">
              <monitoring-chart-widget
                  widget-number="8"
                  v-bind:title="this.controller.temperature"
                  :categories="categories"
                  :chart-series="tempChartSeries"
                  :limit="tempLimit"
                  limitPosition="up"
                  :loading="loading"
              ></monitoring-chart-widget>
            </div>

            <div class="col-12 col-lg-6 col-xxl-3">
              <monitoring-chart-widget
                  widget-number="9"
                  v-bind:title="this.controller.humidity"
                  :categories="categories"
                  :chart-series="humidityChartSeries"
                  :limit="humidityLimit"
                  limitPosition="up"
                  :loading="loading"
              ></monitoring-chart-widget>
            </div>

            <div class="col-12 col-lg-6 col-xxl-3">
              <monitoring-chart-widget
                  widget-number="10"
                  v-bind:title="this.controller.CO2"
                  :categories="categories"
                  :chart-series="co2ChartSeries"
                  :limit="co2Limit"
                  limitPosition="down"
                  :loading="loading"
              ></monitoring-chart-widget>
            </div>

            <div class="col-12 col-lg-6 col-xxl-3">
              <monitoring-chart-widget
                  widget-number="11"
                  v-bind:title="this.controller.EC"
                  :categories="categories"
                  :chart-series="ecChartSeries"
                  :limit="ecLimit"
                  limitPosition="up"
                  :loading="loading"
              ></monitoring-chart-widget>
            </div>

            <div class="col-12 col-lg-6 col-xxl-3">
              <monitoring-chart-widget
                  widget-number="12"
                  v-bind:title="this.controller.pH"
                  :categories="categories"
                  :chart-series="phChartSeries"
                  :limit="phLimit"
                  limitPosition="down"
                  :loading="loading"
              ></monitoring-chart-widget>
            </div>

          </div>
        </div>
    </div>
    <!--end::controller-tabs-->
    <b-modal
      v-model="showModal"
      centered title="BootstrapVue"
      size="xxl"
      hide-footer
      hide-header
    >
      <div class="card card-custom card-stretch" @click="showModal =false">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-normal p-10 float-left my-1 py-2"><span class='icon-xl fas fa-info-circle mr-2'></span>
        {{registerInfo}}</span>
        </h3>
      </div>
      <!--end::Header-->
      </div>
    </b-modal>

  </div>
  <!--end: Farm controller-->
</template>

<script>
import StatsWidget1 from '@/view/content/widgets/dashboard/Widget1.vue';
import StatsWidget13 from '@/view/content/widgets/dashboard/Widget13.vue';
import StatsWidget6 from '@/view/content/widgets/dashboard/Widget6.vue';
import StatsWidget7 from '@/view/content/widgets/dashboard/Widget7.vue';
import GroupWidget from '@/view/content/widgets/dashboard/GroupWidget.vue';
import MonitoringChartWidget from '@/components/MonitoringChartWidget';
import {timestampToDate, getItems} from '@/core/services/funcs';
import {ACT_GET_MY_FARMS} from '@/core/services/variable';


import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "farm-controller-example",
  components: {
    StatsWidget1,
    StatsWidget13,
    StatsWidget6,
    StatsWidget7,
    MonitoringChartWidget,
    GroupWidget
  },
  beforeMount(){
    this.getDates()
    // this.$store.dispatch(ACT_GET_MY_FARMS).then(resp => {
    //   if(getItems(resp).length == 0){
    //     this.showExample = true
    //     this.showModal = true
    //   }
    // })
  },
  data() {
    return {
        temperature: 25.1,
        waterTemperature: 24.3,
        humidity: 57.2,
        co2: 870,
        ph: 64.3,
        ec: 17.3,
        led: 1,
        fan: 1,
        controller:{
            temperature: i18n.t('dashboard.temperature'),
            humidity: i18n.t('dashboard.humidity'),
            CO2: i18n.t('dashboard.CO2'),
            EC: i18n.t('dashboard.EC'),
            pH: i18n.t('dashboard.pH'),
        },
        i18n:{
          close: i18n.t('dashboard.close')
        },
        example: i18n.t('dashboard.example'),
        registerInfo: i18n.t('dashboard.registerInfo'),
        categories: [],
        tempChartSeries: [],
        humidityChartSeries: [],
        co2ChartSeries: [],
        ecChartSeries: [],
        phChartSeries: [],
        loading: false,
        tempLimit : 25,
        humidityLimit : 103,
        co2Limit : 700,
        ecLimit : 3,
        phLimit : 5.5,
        showExample: false,
        showModal: false,
    }
  },
  methods: {
    getDates(){
      for(let i = 1 ; i<=1000; i++){
        let d = new Date()
        const key = timestampToDate(new Date(d.setMinutes(d.getMinutes() - 1000 + i)).getTime(),'yyyy.MM.dd hh:mm')
        if(i<=500){
          this.tempChartSeries.push([key, Number((20 + Math.random()*2).toFixed(2))])
          this.humidityChartSeries.push([key, Number((55 + Math.random()*5).toFixed(2))])
          this.co2ChartSeries.push([key, Number((1000 + Math.random()*100).toFixed(0))])
          this.ecChartSeries.push([key, Number((2 + Math.random()*0.2).toFixed(2))])
          this.phChartSeries.push([key, Number((6 + Math.random()*0.5).toFixed(2))])
        }else{
          this.tempChartSeries.push([key, Number((22 + Math.random()*1).toFixed(2))])
          this.humidityChartSeries.push([key, Number((60 + Math.random()*1).toFixed(2))])
          this.co2ChartSeries.push([key, Number((1200 + Math.random()*50).toFixed(0))])
          this.ecChartSeries.push([key, Number((1.8 + Math.random()*0.1).toFixed(2))])
          this.phChartSeries.push([key, Number((6.4 + Math.random()*0.2).toFixed(2))])
        }
      }
    },

  },
};
</script>
