
<template>
  <div class="page-portal">
    <!--begin::Dashboard-->
    <div class="row">
      <template v-for="(item, i) in filteredInfo.length > 0 ? filteredInfo : info">
        <div class="col-12 col-md-6 col-xxl-4" v-bind:key="i">
          <!--begin::List Widget 1-->
          <div class="card card-custom card-stretch gutter-b dashboard-farm-item">
            <!--begin::Header-->
            <div class="card-header border-0">
              <div class="card-title top">
                <h3 class="card-label">
                  <div class="d-flex align-items-center">
                    <span class="title font-weight-bolder text-dark">{{ item.farmNm }}</span>
                  </div>
                  <!--begin::metadata-->
                  <div class="metadata d-flex align-items-center">
                    <!--begin::metadata-item-->
                    <div class="metadata-item d-flex align-items-center mt-3">
                      <span class="metadata-svg svg-icon svg-icon-light-dark mr-1">
                        <inline-svg v-if="item.farmOwnerPhoneNo" src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Call.svg" />
                      </span>
                      <span class="text font-weight-bold font-size-sm text-muted">{{ getPhoneFormat(item.farmOwnerPhoneNo) }}</span>
                    </div>
                    <!--end::metadata-item-->
                  </div>
                  <!--end::metadata-->
                </h3>
              </div>
              <div class="card-toolbar">
                <b-button pill class="btn-portal" @click="goSmartFarm(item.farmId)">
                  <span class="text mr-2">{{i18n.confirm}}</span>
                  <span class="fas fa-arrow-right"></span>
                </b-button>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
<!--                <div class="farm-map mb-6 mb-lg-0 col-12 col-lg-5">-->
<!--                  <template>-->
<!--                    <div class="bg-light border h-100" style="min-height:130px">-->
<!--                      <img src="https://maps.googleapis.com/maps/api/staticmap?center=37.57866,126.98646&zoom=18&size=500x100&maptype=roadmap&markers=color:red%7Clabel:C%7C37.57866,126.98646&key=AIzaSyDA3gJRTsteMvfvBEIeO9r31oFDVv8pZy0" style="width: 100%; height: 100%"/>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </div>-->
                <ul class="farm-info list-unstyled col-12 col-lg-6 mb-7">

                  <li class="info-item d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 mr-4 symbol-light-dark">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Code/Code.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column font-weight-bold">
                      <a href="javascript:;" class="farm-header text-dark text-hover-primary mb-1">
                        {{ item.farmCd ? item.farmCd : '-' }}
                      </a>
                      <span class="farm-text text-muted">
                        {{i18n.code}}
                      </span>
                    </div>
                    <!--end::Text-->
                  </li>
                  <li class="info-item d-flex align-items-center mt-6">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 mr-4 symbol-light-dark">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Building.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column font-weight-bold">
                      <a href="javascript:;" class="farm-header text-dark text-hover-primary mb-1">
                        {{ item.companyNm }}
                      </a>
                      <span class="farm-text text-muted">
                        {{i18n.name}}
                      </span>
                    </div>
                    <!--end::Text-->
                  </li>

                  <li class="info-item d-flex align-items-center mt-6">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 mr-4 symbol-light-dark">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Cooking/Dish.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column font-weight-bold">
                      <a href="javascript:;" class="farm-header text-dark text-hover-primary mb-1">
                        {{ item.operationSt ? item.operationSt : '-' }}
                      </a>
                      <span class="farm-text text-muted">
                        {{i18n.operationStatus}}
                      </span>
                    </div>
                    <!--end::Text-->
                  </li>
                </ul>

                <ul class="farm-info list-unstyled col-12 col-lg-6 mb-7">

                  <li class="info-item d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 mr-4 symbol-light-dark">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Flower1.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column font-weight-bold">
                      <a href="javascript:;" class="farm-header text-dark text-hover-primary mb-1">
                        {{ item.cultivateType ? item.cultivateType : '-' }}
                      </a>
                      <span class="farm-text text-muted">
                        {{i18n.cultivationType}}
                      </span>
                    </div>
                    <!--end::Text-->
                  </li>

                  <!--begin::Item-->
                  <li class="info-item d-flex align-items-center mt-6">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40 mr-4 symbol-light-dark">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Map/Marker1.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Text-->
                    <div class="d-flex flex-column font-weight-bold">
                      <a href="javascript:;" class="farm-header text-dark text-hover-primary mb-1">
                        {{ item.farmAddr }}
                      </a>
                      <span class="farm-text text-muted">
                        {{i18n.location}}
                      </span>
                    </div>
                    <!--end::Text-->
                  </li>
                  <!--end::Item-->
                  <!--begin::Item-->

                  <!--end::Item-->
                  <!--end::Item-->
                </ul>

              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end: List Widget 1-->
        </div>
      </template>
        <farm-controller-example>
        </farm-controller-example>

        <!--begin::appVersionCheck-->
        <b-modal
          v-model="showVersionCheck"
          centered title="BootstrapVue"
          size="xxl"
          hide-footer
          hide-header
        >
        <div class="row">
          <h2 class="text-center col-12">
            <span class="font-weight-bold mt-15">
              <i class="fas fa-seedling text-primary fa-lg pr-2"></i>{{i18n.updateTitle}}
            </span>
          </h2>
          <div class="col-12 py-10 row text-center">
            <span class="col-12">{{i18n.updateDesc1}}</span>
            <span class="col-12">{{i18n.updateDesc2}}</span>
            <span class="p-3"></span>
            <span class="col-12">{{i18n.updateDesc3}}</span>
          </div>
          <div class="col text-center">
            <button class="btn btn-primary text-center col-10" @click="gotoUpdate()">{{i18n.gotoUpdate}}<span class="fas fa-reply ml-2"></span></button>
          </div>
        </div>
        </b-modal>
        <!--end::appVersionCheck-->

    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import FarmControllerExample from "@/view/content/dashboard/FarmControllerExample.vue";
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {PURGE_AUTH} from '@/core/services/store/auth.module';
import {ACT_GET_MY_FARMS, MUT_SET_FARM_ID, ANDROID_API_KEY, IOS_API_KEY} from '@/core/services/variable';
import {getItems, lengthCheck, phoneFormat} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';
import Vue from 'vue';
import axios from 'axios';

export default {
  name: "Portal",
  components: {
    FarmControllerExample
  },
  beforeMount() {
    // farmId 초기화
    this.$store.commit(MUT_SET_FARM_ID, '')

    this.$store.dispatch(ACT_GET_MY_FARMS).then(resp => {
      if(lengthCheck(resp)) {
        this.info = getItems(resp);
        if(this.info.length === 1){
          this.goSmartFarm(this.info[0].farmId);
        }
        if(this.$route.query.search)
          this.filteredInfo = this.filterFarmBySearch(this.$route.query.search)
      }
    })
    .catch(e => {
      console.error(e);
      // this.$store.commit(PURGE_AUTH);
    });

    if(this.device != 'desktop'){
      const axiosConfig = {
        baseURL: "https://www.t-farmon.com",
        // baseURL: window.location.href.replace('#/portal', '')
      }
      Vue.prototype.$axios = axios.create(axiosConfig)

      this.$axios.get('/version-check').then(resp =>{
        let parser, xmlDoc;

        parser = new DOMParser();
        xmlDoc = parser.parseFromString(resp.data, "text/html");
        this.androidVersion = xmlDoc.getElementById("android.version").innerText
        this.iosVersion = xmlDoc.getElementById("ios.version").innerText
        //tfarmon.com 사이트의 index.html 안에 있는 모바일 버전값과 비교(모바일 배포 시 index.html과 아래 if문의 1.x.x 수정이 필요)
        if(this.androidVersion != "1.2.21" && this.device == 'android'){
          this.showVersionCheck = true
        }else if(this.iosVersion != "1.2.24" && this.device == 'ios'){
          this.showVersionCheck = true
        }
      })
    }
  },
  watch: {
    '$route.query.search': {
        immediate: true,
        handler(search) {
          this.filteredInfo = this.filterFarmBySearch(search)
        }
    }
  },
  computed: {
    device(){
      if(this.$store.state.auth.apikey == ANDROID_API_KEY){
        return 'android'
      }else if(this.$store.state.auth.apikey == IOS_API_KEY){
        return 'ios'
      }
      return 'desktop'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.i18n.title, icon: 'la la-door-open icon-xxl' }]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      info: [],
      filteredInfo: [],
      showVersionCheck: false,
      i18n: {
        title: i18n.t('portal.title'),
        code: i18n.t('portal.code'),
        cultivationType: i18n.t('portal.cultivationType'),
        name: i18n.t('portal.name'),
        location: i18n.t('portal.location'),
        operationStatus: i18n.t('portal.operationStatus'),
        confirm: i18n.t('portal.confirm'),
        updateTitle: i18n.t('portal.updateTitle'),
        updateDesc1: i18n.t('portal.updateDesc1'),
        updateDesc2: i18n.t('portal.updateDesc2'),
        updateDesc3: i18n.t('portal.updateDesc3'),
        gotoUpdate: i18n.t('portal.gotoUpdate'),
      },
      androidVersion: '',
      iosVersion: '',
    };
  },
  methods: {
    getPhoneFormat(phoneNo){
      if(phoneNo) return phoneFormat(phoneNo)
      return ''
    },
    goSmartFarm(farmId){
      this.$router.push('smartfarm/' + farmId + '/dashboard');
    },
    filterFarmBySearch(search) {
      const farmNameFilteredBySearch = this.info.filter(farms => farms.farmNm.includes(search))
      return farmNameFilteredBySearch
    },
    gotoUpdate(){
      if(this.device == 'android'){
        // location.href='https://play.google.com/store/apps/details?id=com.plantfarm.app'
        window.open('https://play.google.com/store/apps/details?id=com.plantfarm.app')
      }else if(this.device == 'ios'){
        // location.href='https://apps.apple.com/us/app/tfarmon-%ED%8B%B0%ED%8C%9C%EC%98%A8/id1628052797'
        window.open('https://apps.apple.com/us/app/tfarmon-%ED%8B%B0%ED%8C%9C%EC%98%A8/id1628052797')
      }
    }
  }
};
</script>
